import React, { FC, useEffect, useState, memo, useRef } from "react";
import { Drawer, Spin, Carousel, Form } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import { CarouselRef } from "antd/lib/carousel";
import { BlobServiceClient } from "@azure/storage-blob";
import { useSelector, useDispatch } from "react-redux";
import { Appointment, serviceSAS } from "shared";
import { WorkshopService, WorkHours } from "shared/src/data-models/Workshop";
import { AppointmentAPI } from "../../../../Services/axios";
import { getSasToken, getUser } from "../../../../../stores/selectors";
import Compressor from "compressorjs";
import { SasTokenHandler } from "../../../../Utilities";
import { HeaderInfo } from "./HeaderInfo";
import { ReceptionData } from "./ReceptionData";
import { PhysicalInspection } from "./PhysicalInspection";
import { Inventory } from "./Inventory";
import { PhysicalDetails, WillWash } from "./PhysicalDetails";

import {
  InternalInventory,
  TrunkInventory,
  GloveBoxInventory,
} from "./InventoryItems";

import moment from "moment";

import { CalendarDeleteIcon } from "../../../../../assets/SvgIcons";
import { ConfirmationModal } from "../../ConfirmationModal";
import { CkButton, CkMessage } from "../../../../../CkUI";
import "./styles.css";

export interface ICow {
  value: string;
  label: string;
}

export interface IInv {
  IsChecked: boolean;
  VehicleAccesoryCode: string;
}

export interface ISelectsItems {
  value: string;
  label: string;
}

export interface IFile {
  uid: string;
  name: string;
  status: string;
  file: any;
  fileName?: string;
  photoUrl?: string;
  preview?: string;
}
export interface IForms {
  formData: any;
  formStatus: any;
  formInventory: any;
}

export interface ITrackingPhoto {
  url: string;
  FileName: string;
  FilePosition: number;
}

export interface ITrackingPhotoResponse {
  url: string;
  fileName: string;
  filePosition: number;
}

export type TVehicleType =
  | "AUTOS"
  | "VEHICULOS DE CARGA LIGERA"
  | "MOTOCICLETA";

interface IUploadAttachmentOutput {
  fileName: string;
  photoUrl: string;
}

type TUploadAttachmentOutput = IUploadAttachmentOutput | null;

type InputObject = {
  [key: string]: boolean | string;
};

type OutputObject = {
  IsChecked: boolean;
  VehicleAccesoryCode: string;
}[];

interface IProps {
  receptionConfirmation: boolean;
  setReceptionConfirmation: Function;
  appointmentInfo: Appointment;
  appointmentId: string;
  hours: WorkHours[];
  workshopId: string;
  services: WorkshopService[];
  packages: any[];
  handleConfirm: Function;
  coworkers: Array<any>;
  statusAppointment: string;
}
const TokenTimeToRefresh = 180000;

export const FooterRender = (
  isOwn: string | undefined,
  currentStep: number,
  isReadOnly: boolean,
  isDisabled: Function
) => {
  const lastStep = () => {
    if (isOwn == "carker" && currentStep == 7) {
      return true;
    } else if (isOwn == "own" && currentStep == 5) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="footer-options">
      <CkButton
        style={{ display: isReadOnly && lastStep() ? "none" : "block" }}
        disabled={!isDisabled()}
        htmlType="submit"
        variant="primary"
        color="primary"
      >
        {lastStep() ? "Guardar y finalizar" : "Siguiente"}
      </CkButton>
      <CkButton
        variant="link"
        color="primary"
        htmlType="reset"
        className="--custom blue-cancel"
      >
        Regresar
      </CkButton>
    </div>
  );
};

const NEWReceptionFormModal: FC<IProps> = ({
  receptionConfirmation,
  setReceptionConfirmation,
  appointmentInfo,
  appointmentId,
  hours,
  workshopId,
  services,
  packages,
  handleConfirm,
  coworkers,
  statusAppointment,
}) => {
  const user = useSelector(getUser);
  const carouselRef = useRef<CarouselRef>(null);
  const loadingMessageKey = useRef<string>("");
  const [PhysicalInspectionForm] = Form.useForm();
  const [InventoryForm] = Form.useForm();
  const [PhysicalDetailsForm] = Form.useForm();
  const [WillWashForm] = Form.useForm();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadingForm, setUploadingForm] = useState<boolean>(false);

  /** States */
  const [coworkersItems, setCoworkersItems] = useState<Array<ICow>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [exitModal, setExitModal] = useState<boolean>(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);
  const [appointmentTracking, setAppointmentTracking] = useState<any>();

  //VEHICLE DATA STATES
  const [currentDate, SetCurrentDate] = useState<moment.Moment>();
  const [currentTime, SetCurrentTime] = useState<string>();

  //VEHICLE STATUS STATES
  const [sliderValue, setSliderValue] = useState<number>(25);

  //FORMAT TYPE
  const [isOwn, setIsOwn] = useState<"own" | "carker" | undefined>(undefined);
  const [isWash, setIsWash] = useState<boolean>(false);

  //STAFF
  const [workshopStaff, setWorkshopStaff] = useState<string>(user?.id || "0");

  //UTILITIES
  const sasToken: string = useSelector(getSasToken);
  const sasTokenHandler = new SasTokenHandler("");
  const dispatch = useDispatch();
  let blobService: any = null;
  const account = process.env.REACT_APP_BLOB_ACCOUNT as string;
  const containerName = "up-images-container";
  const [containerClient, setContainerClient] = useState<any>();
  const intervalID = useRef<any>(null);

  /** Effects */
  useEffect(() => {
    getCoworkers();
  }, [coworkers]);

  useEffect(() => {
    intervalID.current = setInterval(() => {
      const tokenValidation = sasTokenHandler.setToken(sasToken);
      // If token in handler is invalid, we must request a new token
      if (tokenValidation.isValid === false) getSasTokenAzure();
      // If token in handler is valid, but valid time less than TokenTimeToRefresh, we must set a timeout
      if (
        tokenValidation.isValid === true &&
        tokenValidation.validTime < TokenTimeToRefresh
      )
        window.setTimeout(getSasTokenAzure, tokenValidation.validTime);
    }, TokenTimeToRefresh);
    return () => {
      intervalID.current && clearInterval(intervalID.current);
    };
  }, []);

  useEffect(() => {
    const tokenValidation = sasTokenHandler.setToken(sasToken);
    // If token in handler is invalid, we must request a new token
    if (tokenValidation.isValid === false) getSasTokenAzure();
    if (tokenValidation.isValid === true) {
      // If token in handler is valid, we recreate the blob
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      setContainerClient(blobService.getContainerClient(containerName));
      // If token in handler is valid, but valid time less than TokenTimeToRefresh, we must set a timeout
      if (tokenValidation.validTime < TokenTimeToRefresh)
        window.setTimeout(getSasTokenAzure, tokenValidation.validTime);
    }
  }, [sasToken]);

  useEffect(() => {
    if (receptionConfirmation === false) return;
    if (appointmentId === undefined) return;
    AppointmentAPI.getTrackingReception(appointmentId)
      .then((response) => {
        if (response && response.data) {
          // Order photos, just in case
          const trackingPhotos: ITrackingPhotoResponse[] = [];
          response.data.trackingPhotos.forEach(
            (image: ITrackingPhotoResponse) => {
              trackingPhotos[image.filePosition - 1] = image;
            }
          );
          setAppointmentTracking({
            ...response.data,
            // ...{ trackingPhotos: trackingPhotos },
          });
          if (response.data.appointmentTrackingId > 0) {
            setIsReadOnly(true);
            ForceDefaultValues(response.data);
          } else {
            setIsReadOnly(false);
          }
          const receptionDate = appointmentInfo.appointmentHistoryStatus.filter(
            (status: any) => status.statusCode === "RECEP"
          )[0];
          if (receptionDate !== undefined) {
            const date = moment(
              receptionDate.statusModifiedDate.endsWith("Z")
                ? receptionDate.statusModifiedDate
                : `${receptionDate.statusModifiedDate}Z`
            );
            SetCurrentDate(date);
            SetCurrentTime(date.format("HH:mm"));
          }
        } else {
          setAppointmentTracking(undefined);
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setAppointmentTracking(undefined);
      });
  }, [receptionConfirmation, appointmentId]);

  const ForceDefaultValues = (data: any) => {
    const isCrFormat: boolean =
      data.trackingPhotos.filter(
        (item: any) => item.filePosition >= 5 && item.filePosition <= 9
      ).length > 0
        ? false
        : true;

    if (isCrFormat) {
      setIsOwn("carker");
    } else {
      setIsOwn("own");
    }

    setWorkshopStaff(data.createdBy.toString());

    PhysicalInspectionForm.setFieldsValue({
      formatType: isCrFormat ? "carker" : "own",
      Mileage: parseInt(data.vehicle.km || "0").toString(),
      fuelLevel: data.vehicleGasLevel,
    });

    WillWashForm.setFieldsValue({
      cleanService: data.needWash,
    });

    InventoryForm.setFieldsValue({
      AccesoryAdditionalDetails: data.accesoryAdditionalDetails,
    });

    data.vehicleAccesories.map((item: any) => {
      InventoryForm.setFieldValue(item.vehicleAccesoryCode, true);
    });

    setSliderValue(data.vehicleGasLevel);
  };

  const handleResetAttachments = () => {
    PhysicalInspectionForm.setFieldValue("document", undefined);
    PhysicalInspectionForm.setFieldValue("carkerDocuments", undefined);
    PhysicalDetailsForm.setFieldValue("carPart_0", undefined);
    PhysicalDetailsForm.setFieldValue("carPart_1", undefined);
    PhysicalDetailsForm.setFieldValue("carPart_2", undefined);
    PhysicalDetailsForm.setFieldValue("carPart_3", undefined);
  };

  function scrollToTopOfAll() {
    const elements = document.querySelectorAll(".reception-step-resize");
    elements.forEach((element) => {
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }

  const nextSlide = (slide: number) => {
    // if (currentStep < 2) {
    carouselRef?.current?.goTo(slide);
    setCurrentStep(slide);
    // }
  };

  const prevSlide = (slide: number) => {
    if (currentStep > 0) {
      carouselRef?.current?.goTo(slide);
      setCurrentStep(slide);
    }
  };

  const getCoworkers = () => {
    let coworkersArray: Array<ICow> = coworkers.map((res) => {
      return {
        value: res.id,
        label: `${res.name} ${res.lastName}`,
        photoUrl: res.photoUrl,
      };
    });

    setCoworkersItems([...coworkersArray]);
  };

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  const uploadFileAndCompress = async (
    file: any
  ): Promise<TUploadAttachmentOutput> => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `receptionForm/${appointmentId}/${date.getTime()}_${
        file.name
      }`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      try {
        new Compressor(file, {
          quality: 0.6,
          convertTypes:
            "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
          convertSize: 3000000,
          async success(result) {
            await blockBlobClient.upload(result, result.size);
            let attached = {
              fileName: file.name,
              photoUrl: blockBlobClient.url.split("?")[0],
            };
            resolve(attached);
          },
        });
      } catch (error) {
        console.info("error compressing and uploading file", error);
        resolve(null);
      }
    });
  };

  const uploadFile = async (
    file: IFile | any
  ): Promise<TUploadAttachmentOutput> => {
    return new Promise(async (resolve, reject) => {
      let date = new Date();
      let fileName = `receptionForm/${appointmentId}/${date.getTime()}_${
        file.name
      }`;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      try {
        await blockBlobClient.upload(file, file.size);
        let attached = {
          fileName: file.name,
          photoUrl: blockBlobClient.url.split("?")[0],
        };
        resolve(attached);
      } catch (error) {
        console.info("error uploading file", error);
        resolve(null);
      }
    });
  };

  /**
   *
   * @param images images extracted from canva
   * @param attachments images attached
   * @returns
   */
  const uploadTrackingPhotos = async (
    images: ITrackingPhoto[],
    attachments: any[],
    count: number
  ): Promise<ITrackingPhoto[]> => {
    // Create the container client
    const blobService = new BlobServiceClient(`${account}?${sasToken}`);
    const client = blobService.getContainerClient(containerName);

    // This will be the output
    const output: any[] = [];
    await Promise.all(
      images.map(async (image) => {
        const date = new Date();
        const fileName = `receptionForm/${appointmentId}/${date.getTime()}_${
          image.FileName
        }.jpeg`;
        const content = image.url.split("base64,")[1];
        const blockBlobClient = client.getBlockBlobClient(fileName);
        const blobOptions = {
          blobHTTPHeaders: { blobContentType: "image/jpeg" },
          "x-ms-version": "2017-11-09",
        };
        const buffer = Buffer.from(content, "base64");
        await blockBlobClient.upload(buffer, buffer.byteLength, blobOptions);
        output[image.FilePosition - 1] = {
          FilePosition: image.FilePosition,
          FileName: fileName,
          PhotoUrl: blockBlobClient.url.split("?")[0],
        };
        return output[image.FilePosition - 1];
      })
    );

    // Loop through attachments
    await Promise.all(
      attachments.map(async (file: IFile | any, id: number) => {
        let uploadRequest: {
          fileName: string;
          photoUrl: string;
        } | null;

        // If is image, compress image and then upload it
        if (file.type.toString().startsWith("image/")) {
          uploadRequest = await uploadFileAndCompress(file.originFileObj);
        } else {
          // else, just upload it
          uploadRequest = await uploadFile(file.originFileObj);
        }
        if (uploadRequest === null) return;
        // update attachment object
        // output[id + 4] = {
        output[id] = {
          // FilePosition: id + 5,
          FilePosition: count + id,
          FileName: uploadRequest?.fileName,
          PhotoUrl: uploadRequest?.photoUrl,
        };
        // return output[id + 4];
        return output[id];
      })
    );
    return output;
  };

  function transform(input: InputObject): OutputObject {
    const output: OutputObject = [];

    for (const key in input) {
      if (input[key] === true) {
        output.push({
          IsChecked: true,
          VehicleAccesoryCode: key.toUpperCase(),
        });
      }
    }

    return output;
  }

  const uploadWithPosition = async (
    list: any[],
    position: number,
    isCarList?: boolean
  ) => {
    try {
      const fileList = list;
      let TrackingPhotos: any[] = [];

      const newImages = await uploadTrackingPhotos(
        isCarList ? list : [],
        isCarList ? [] : fileList || [],
        position
      );

      // @ts-ignore
      TrackingPhotos = [...newImages, ...TrackingPhotos];

      if (
        TrackingPhotos.length === 0 ||
        TrackingPhotos.some((photo: ITrackingPhoto | null) => photo === null) ||
        fileList.length !== TrackingPhotos.length
      ) {
        CkMessage({
          type: "error",
          text: "Se produjo un error al cargar las fotos. Intentelo nuevamente."
        });
        setUploadingForm(false);
        return [];
      }

      return TrackingPhotos;
    } catch (error) {
      console.error("Se produjo un error: ", error);
    }
  };

  const createFormReception = async () => {
    const PhysicalInspectionValues = PhysicalInspectionForm.getFieldsValue();
    const InventoryValues = InventoryForm.getFieldsValue();
    const PhysicalDetailsValues = PhysicalDetailsForm.getFieldsValue();
    const WillWashValues = WillWashForm.getFieldsValue();

    if (
      user &&
      PhysicalInspectionValues &&
      PhysicalDetailsValues &&
      WillWashValues &&
      InventoryValues
    ) {
      setExitModal(false);
      setUploadingForm(true);
      loadingMessageKey.current = CkMessage({type: "loading", text: "Guardando datos..."});

      let date = currentDate || moment();
      if (currentTime !== undefined) {
        const splitedTime = currentTime?.split(":");
        splitedTime[0] && date.hour(parseInt(splitedTime[0]));
        splitedTime[1] && date.minutes(parseInt(splitedTime[1]));
      }

      const vehicleInfo: {
        Nickname: string;
        TypeCode: string;
        TypeName: string;
        BrandCode: string;
        BrandName: string;
        IsCustomBrand: boolean;
        YearCode: string;
        Year: string;
        ModelCode: string;
        ModelName: string;
        IsCustomModel: boolean;
        FuelTypeCode: string;
        FuelTypeName: string;
        LicensePlate: string;
        IsEnabled: boolean;
        Km: number | string;
        VehicleId: number | "" | null;
      } = {
        Nickname: "",
        TypeCode: appointmentInfo.vehicle.typeCode || "",
        TypeName: appointmentInfo.vehicle.typeCode || "",
        BrandCode: appointmentInfo.vehicle.brandCode || "",
        BrandName: appointmentInfo.vehicle.brandCode || "",
        //@ts-ignore
        IsCustomBrand: appointmentInfo.vehicle.isCustomBrand,
        YearCode: appointmentInfo.vehicle.yearCode?.toString() || "",
        Year: appointmentInfo.vehicle.yearCode?.toString() || "",
        ModelCode: appointmentInfo.vehicle.modelCode || "",
        ModelName: appointmentInfo.vehicle.modelCode || "",
        //@ts-ignore
        IsCustomModel: appointmentInfo.vehicle.isCustomModel,
        FuelTypeCode: appointmentInfo.vehicle.fuelTypeCode || "",
        FuelTypeName: appointmentInfo.vehicle.fuelTypeCode || "",
        LicensePlate: appointmentInfo.vehicle.licensePlate || "",
        IsEnabled: true,
        Km: parseInt(PhysicalInspectionValues.Mileage || "0"),
        VehicleId: appointmentInfo.vehicle.vehicleId!,
      };

      if (!!appointmentInfo.vehicle.nickname) {
        vehicleInfo.Nickname = appointmentInfo.vehicle.nickname;
      } else {
        const { BrandName, ModelName } = vehicleInfo;
        vehicleInfo.Nickname = `${BrandName.toUpperCase()} - ${ModelName.toUpperCase()}`;
      }

      const localPayload: any = {
        TypeCode: "RECEPTION",
        ReceptionDate: date.toISOString(),
        SameDriver: true,
        CreatedBy: workshopStaff || user.id,
        VehicleGasLevel: PhysicalInspectionValues.fuelLevel || 0,
        IsDirty: false,
        NeedWash: WillWashValues.cleanService || false,
        AccesoryAdditionalDetails:
          InventoryValues.AccesoryAdditionalDetails || "",
        AdditionalDetails: "",
        Vehicle: vehicleInfo,
        VehicleAccesories: transform(InventoryValues),
        ServicesRequired: [],
        PackagesRequired: [],
        TrackingPhotos: [],
      };

      // First validate the token
      const tokenValidation = sasTokenHandler.setToken(sasToken);
      if (tokenValidation.isValid === false) {
        getSasTokenAzure();
        CkMessage({
          type: "error",
          text: "Se produjo un error al cargar las fotos. Intentelo nuevamente.",
          messageToDestroy: loadingMessageKey.current
        });
        loadingMessageKey.current = "";
        setUploadingForm(false);
        return false;
      }

      // PHOTOS: OWN FORMAT
      if (
        PhysicalInspectionValues &&
        PhysicalInspectionValues.document &&
        PhysicalInspectionValues.document.fileList
      ) {
        let physicalList = PhysicalInspectionValues.document.fileList || [];
        if (isOwn == "own" && physicalList && physicalList.length > 0) {
          const result = await uploadWithPosition(physicalList, 5);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      // PHOTOS: CARKER FORMAT > BOARD
      if (
        PhysicalInspectionValues &&
        PhysicalInspectionValues.carkerDocuments &&
        PhysicalInspectionValues.carkerDocuments.fileList
      ) {
        let physicalCarkerList =
          PhysicalInspectionValues.carkerDocuments.fileList || [];
        if (
          isOwn == "carker" &&
          physicalCarkerList &&
          physicalCarkerList.length > 0
        ) {
          const result = await uploadWithPosition(physicalCarkerList, 10);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      // PHOTOS: CARKER FORMAT > CANVAS
      if (PhysicalDetailsValues && PhysicalDetailsValues.carFileList) {
        let physicalCarkerList = PhysicalDetailsValues.carFileList || [];
        if (
          isOwn == "carker" &&
          physicalCarkerList &&
          physicalCarkerList.length > 0
        ) {
          const result = await uploadWithPosition(physicalCarkerList, 0, true);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      // PHOTOS: CARKER FORMAT > ATT > FRONT
      if (
        PhysicalDetailsValues &&
        PhysicalDetailsValues.carPart_0 &&
        PhysicalDetailsValues.carPart_0.fileList
      ) {
        let physicalCarkerList = PhysicalDetailsValues.carPart_0.fileList || [];
        if (
          isOwn == "carker" &&
          physicalCarkerList &&
          physicalCarkerList.length > 0
        ) {
          const result = await uploadWithPosition(physicalCarkerList, 15);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      // PHOTOS: CARKER FORMAT > ATT > RIGHT
      if (
        PhysicalDetailsValues &&
        PhysicalDetailsValues.carPart_1 &&
        PhysicalDetailsValues.carPart_1.fileList
      ) {
        let physicalCarkerList = PhysicalDetailsValues.carPart_1.fileList || [];
        if (
          isOwn == "carker" &&
          physicalCarkerList &&
          physicalCarkerList.length > 0
        ) {
          const result = await uploadWithPosition(physicalCarkerList, 20);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      // PHOTOS: CARKER FORMAT > ATT > BACK
      if (
        PhysicalDetailsValues &&
        PhysicalDetailsValues.carPart_2 &&
        PhysicalDetailsValues.carPart_2.fileList
      ) {
        let physicalCarkerList = PhysicalDetailsValues.carPart_2.fileList || [];
        if (
          isOwn == "carker" &&
          physicalCarkerList &&
          physicalCarkerList.length > 0
        ) {
          const result = await uploadWithPosition(physicalCarkerList, 25);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      // PHOTOS: CARKER FORMAT > ATT > BACK
      if (
        PhysicalDetailsValues &&
        PhysicalDetailsValues.carPart_3 &&
        PhysicalDetailsValues.carPart_3.fileList
      ) {
        let physicalCarkerList = PhysicalDetailsValues.carPart_3.fileList || [];
        if (
          isOwn == "carker" &&
          physicalCarkerList &&
          physicalCarkerList.length > 0
        ) {
          const result = await uploadWithPosition(physicalCarkerList, 30);
          if (result) {
            localPayload.TrackingPhotos = [
              ...localPayload.TrackingPhotos,
              ...result,
            ];
          }
        }
      }

      completeForm(localPayload, vehicleInfo);
    }
  };

  const completeForm = async (payload: any, vehicleInfo: any) => {
    AppointmentAPI.addReception(payload, appointmentId)
      .then((response) => {
        if (response.data) {
          CkMessage({
            type: "success",
            text: "Se ha guardado con éxito el formulario de recepción del vehículo. Proceder con el siguiente paso.",
            ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
          });
          handleConfirm("RECEP", vehicleInfo.LicensePlate, vehicleInfo.Km);
          setReceptionConfirmation(false);
          clearForm();
        }
      })
      .catch((err) => {
        CkMessage({
          type: "error",
          text: "Ocurrio un error, intenta nuevamente.",
          ...(loadingMessageKey.current ? {messageToDestroy: loadingMessageKey.current} : {})
        });
      })
      .finally(() => {
        loadingMessageKey.current = "";
        setUploadingForm(false);
      });
  };

  const clearForm = () => {
    setCurrentStep(0);
    setCoworkersItems([]);
    setIsLoading(true);
    setIsReadOnly(false);
    setAppointmentTracking(undefined);

    setReceptionConfirmation(false);

    clearDataForm();
  };

  const clearDataForm = () => {
    SetCurrentDate(undefined);
    SetCurrentTime(undefined);
  };

  const getNextInventoryStep = () => {
    return currentStep + 1;
  };

  const getBackInventoryStep = () => {
    return currentStep - 1;
  };

  return (
    <Drawer
      className="recepction-form-drawer"
      open={receptionConfirmation}
      onClose={() => {
        if (isReadOnly) {
          clearForm();
          setReceptionConfirmation(false);
        } else {
          setConfirmCloseModal(true);
        }
      }}
      closeIcon={null}
      zIndex={5000}
      destroyOnClose
      title={null}
    >
      <Spin
        className="uploading-loader"
        size="large"
        spinning={uploadingForm}
      />
      <Spin wrapperClassName="drawer-loader" size="large" spinning={isLoading}>
        <div className="flex-mid-content">
          <LeftOutlined
            className="back-arrow"
            onClick={() => {
              if (isReadOnly) {
                clearForm();
                setReceptionConfirmation(false);
              } else {
                setConfirmCloseModal(true);
              }
            }}
          />

          <div className="mid-content">
            <HeaderInfo
              workshopId={workshopId}
              currentStep={currentStep}
              isOwn={isOwn}
            />
            <Carousel
              ref={carouselRef}
              dots={false}
              waitForAnimate={true}
              effect="scrollx"
              beforeChange={() => {
                // scrollTopOnDrawer();
                scrollToTopOfAll();
              }}
              afterChange={(e) => {
                // updateCarouselHeight(-1, false);
              }}
              swipeToSlide={false}
              touchMove={false}
            >
              {/* Step 0 */}
              <ReceptionData
                // Controll actions
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                // isReadOnly
                isReadOnly={isReadOnly}
                // Info
                appointmentInfo={appointmentInfo}
                workshopId={workshopId}
                isOwn={isOwn}
                currentStep={currentStep}
                coworkersItems={coworkersItems}
                workshopStaff={workshopStaff}
                setWorkshopStaff={setWorkshopStaff}
              />

              {/* Step 1 */}
              <PhysicalInspection
                // Form
                PhysicalInspectionForm={PhysicalInspectionForm}
                // Controll actions
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                // isReadOnly
                isReadOnly={isReadOnly}
                // Info
                workshopId={workshopId}
                isOwn={isOwn}
                currentStep={currentStep}
                // Set Info
                setIsOwn={setIsOwn}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                appointmentTracking={appointmentTracking}
                sasToken={sasToken}
                handleResetAttachments={handleResetAttachments}
              />

              {/* Step 2 */}
              {isOwn == "carker" && (
                <PhysicalDetails
                  PhysicalDetailsForm={PhysicalDetailsForm}
                  // Controll actions
                  nextSlide={nextSlide}
                  prevSlide={prevSlide}
                  // isReadOnly
                  isReadOnly={isReadOnly}
                  // Custom
                  title="Detalles físicos del vehículo"
                  next={getNextInventoryStep()}
                  back={getBackInventoryStep()}
                  willWash={false}
                  // Info
                  workshopId={workshopId}
                  isOwn={isOwn}
                  currentStep={currentStep}
                  appointmentTracking={appointmentTracking}
                  carTypeCode={appointmentInfo.vehicle.typeCode || "AUTOS"}
                  sasToken={sasToken}
                />
              )}

              {/* Step 3 */}
              {isOwn == "carker" && (
                <WillWash
                  WillWashForm={WillWashForm}
                  // Controll actions
                  nextSlide={nextSlide}
                  prevSlide={prevSlide}
                  // isReadOnly
                  isReadOnly={isReadOnly}
                  // Custom
                  title="Detalles físicos del vehículo"
                  next={getNextInventoryStep()}
                  back={getBackInventoryStep()}
                  setIsWash={setIsWash}
                  // Info
                  isOwn={isOwn}
                  currentStep={currentStep}
                />
              )}

              {/* Step 4 */}
              <Inventory
                // Form
                InventoryForm={InventoryForm}
                // Controll actions
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                // isReadOnly
                isReadOnly={isReadOnly}
                // Custom
                title={InternalInventory.title}
                list={InternalInventory.list}
                next={getNextInventoryStep()}
                back={getBackInventoryStep()}
                // Info
                isOwn={isOwn}
                currentStep={currentStep}
                workshopId={workshopId}
              />

              {/* Step 5 */}
              <Inventory
                // Form
                InventoryForm={InventoryForm}
                // Controll actions
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                // isReadOnly
                isReadOnly={isReadOnly}
                // Custom
                title={TrunkInventory.title}
                list={TrunkInventory.list}
                next={getNextInventoryStep()}
                back={getBackInventoryStep()}
                // Info
                isOwn={isOwn}
                currentStep={currentStep}
                workshopId={workshopId}
              />

              {/* Step 6 */}
              <Inventory
                // Form
                InventoryForm={InventoryForm}
                // Controll actions
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                // isReadOnly
                isReadOnly={isReadOnly}
                // Custom
                title={GloveBoxInventory.title}
                list={GloveBoxInventory.list}
                next={getNextInventoryStep()}
                back={getBackInventoryStep()}
                // Info
                isOwn={isOwn}
                currentStep={currentStep}
                workshopId={workshopId}
              />

              {/* Step 7 */}
              <Inventory
                // Form
                InventoryForm={InventoryForm}
                // Controll actions
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                // isReadOnly
                isReadOnly={isReadOnly}
                // Custom
                title="Comentarios"
                list={[]}
                next={getNextInventoryStep()}
                back={getBackInventoryStep()}
                isComment
                // createFormReception={createFormReception}
                setExitModal={setExitModal}
                // Info
                isOwn={isOwn}
                currentStep={currentStep}
                workshopId={workshopId}
              />
            </Carousel>
          </div>
        </div>
      </Spin>
      <ConfirmationModal
        zIndex={5001}
        visible={confirmCloseModal}
        onOk={() => {
          clearForm();
          setReceptionConfirmation(false);
          setConfirmCloseModal(false);
        }}
        onCancel={() => setConfirmCloseModal(false)}
        title="Advertencia"
        message="Al continuar, se guardaran los cambios, pero no se completará el formulario al momento de cerrarlo. ¿Desea proceder?"
        icon={<CalendarDeleteIcon />}
      />
      <ConfirmationModal
        zIndex={5001}
        visible={exitModal}
        onOk={() => createFormReception()}
        onCancel={() => setExitModal(false)}
        title="Advertencia"
        message="Al continuar, no podrá regresar a editar esta información. ¿Desea proceder?"
        icon={<CalendarDeleteIcon />}
      />
    </Drawer>
  );
};

export default memo(NEWReceptionFormModal);
